import { render, staticRenderFns } from "./FullscreenCarousel.vue?vue&type=template&id=dd2c5248&scoped=true&"
import script from "./FullscreenCarousel.vue?vue&type=script&lang=js&"
export * from "./FullscreenCarousel.vue?vue&type=script&lang=js&"
import style0 from "./FullscreenCarousel.vue?vue&type=style&index=0&id=dd2c5248&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dd2c5248",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {VideoSlide: require('/var/www/html/preview/components/VideoSlide.vue').default,ImageSlide: require('/var/www/html/preview/components/ImageSlide.vue').default})
